const start = {
    init() {
            header.init(); // start de header watch function
    }
}


const header = {
    location: document.querySelector("header"),
    logoLocationHeader: document.querySelector("header h1"),
    logoLocationPage: document.querySelector("main .landing .content h1"),
    animate: true,
    animatedH: null,
    init() {
        // get some cordinates
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }

        let logoPageHeight = this.location.offsetHeight;
        let offset = this.logoLocationPage.getBoundingClientRect().top - logoPageHeight;

        // set a event listner on the scroll event
        window.addEventListener('scroll', () => {
            if (offset < window.scrollY ) { // iff offset is reached run a one of the 2 fucntion
                this.addAnimate();
                this.animate = false; //boelan to only run the function once
            } else {
                this.removeAnimate();
                this.animate = true; //boelan to only run the function once
            }
        });
    },
    addAnimate() {
        if(this.animate) {
            // get the cordinates
            let leftLocationLogo = this.logoLocationPage.offsetLeft;
            let topLocationLogo = this.logoLocationPage.getBoundingClientRect();


            //clone a h1 place holder, this prevent the page moving
            this.animatedH = this.logoLocationPage.cloneNode(true);
            this.logoLocationPage.classList.add("invisible", "placeHolder");
            document.querySelector(".content").insertBefore(this.animatedH, this.logoLocationPage);

            // Give the h1 a top and a left for posision fixed, a start for animating it
            this.animatedH.style.left = leftLocationLogo + "px";
            this.animatedH.style.top = topLocationLogo.top + "px";
            this.animatedH.style.margin = "0";
            this.animatedH.classList.add("fixed");

            setTimeout(() => {
                // run the animation, there is a delay becouse otherwise the animation wont start
                this.animatedH.classList.add("animate");
                setTimeout(() => { // After animation remove the h1 and replace for the real home button
                    this.animatedH.classList.add("invisible");
                    this.logoLocationHeader.classList.add("show");
                }, 1000);
            }, 10);
        }
    },
    removeAnimate() {
        if (!this.animate) {
            // Reset every thing
            this.animatedH.classList.remove("invisible");
            this.logoLocationHeader.classList.remove("show");
            this.animatedH.classList.remove("animate");
            this.animatedH.style.marginTop = "0.67em";
            setTimeout(() => { // Timeout to remove after animation
                this.animatedH.remove();
                document.querySelector(".placeHolder").classList.remove("invisible", "placeHolder");
            }, 1000);

        }
    }
}

start.init() // Start the main js function
